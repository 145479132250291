.frame {
  width: 30%;
}
.frames {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 2500px;
  align-items: center;
  gap: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .frame {
    width: 45%;
  }

  .frames {
    height: 4700px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .frame {
    width: 95%;
  }

  .frames {
    height: auto;
  }
}
