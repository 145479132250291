.testimonies {
  width: 1200px;
  padding: 50px 0;
}

.testimony-cards-scroll {
  height: 500px;
  overflow-y: scroll;
}

.testimony-cards {
  display: flex;
  width: 900px;
  height: 2200px;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 auto;
  gap: 30px;
}

.testimony-card {
  background-color: rgba(186, 73, 38, 1);
  color: #fff;
  border-radius: 20px;
  width: 435px;
  font-size: 20px;
  font-family: "Krub", sans-serif;
  line-height: normal;
}

.testimony {
  padding: 20px;
  text-align: left;
}

.testifier {
  text-align: right;
  padding: 20px;
  font-size: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .testimonies {
    width: 750px;
    padding: 50px 0;
    margin: 0 auto;
  }

  .testimony-cards-scroll {
    width: 750px;
    height: 500px;
  }

  .testimony-cards {
    width: 700px;
    height: auto;
    gap: 10px;
  }

  .testimony-card {
    width: 700px;
    font-size: 15px;
  }

  .testifier {
    padding: 10px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .testimonies {
    width: 350px;
    padding: 50px 10px;
    margin: 0 auto;
  }

  .testimony-cards-scroll {
    width: 350px;
    height: 500px;
  }

  .testimony-cards {
    width: 310px;
    height: auto;
    gap: 10px;
  }

  .testimony-card {
    width: 300px;
    font-size: 15px;
  }

  .testifier {
    padding: 10px;
  }
}
