@import url("https://fonts.googleapis.com/css2?family=Kaisei+Decol:wght@400;700&family=Krub:wght@200;400;700&display=swap");
@import url("./reset.css");
@import url("/src/pages/style/header.css");
@import url("/src/pages/style/fixables.css");
@import url("/src/pages/style/bio.css");
@import url("/src/pages/style/testimonies.css");
@import url("/src/pages/style/footer.css");
@import url("/src/pages/style/request.css");
@import url("/src/pages/style/certifications.css");
@import url("/src/pages/style/resources.css");
@import url("/src/pages/style/faq.css");
@import url("/src/pages/style/contactform.css");

.App {
  text-align: center;
  background-color: #0a2c31;
}

.body {
  background-color: #19707c;
  background-image: url("/src/pages/images/big-ying.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.page-title {
  margin: 100px auto;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .body {
    max-width: 1000px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .body {
    max-width: 767px;
  }
}
