.bio {
  background-color: rgba(255, 189, 66, 1);
  padding: 50px 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-picture {
  width: 450px;
  height: 600px;
  background-image: url(../images/profile-blue.png);
  transition: 0.5s;
  border-radius: 20px;
  display: inline;
}

.profile-picture:hover {
  background-image: url(../images/profile-color.png);
  transition: 0.5s;
}

.name {
  font-size: 60px;
  font-family: "Kaisei Decol", serif;
  font-weight: 700;
  text-align: center;
  width: 420px;
  margin-bottom: 30px;
}

.bio-tagline {
  color: #ba4926;
  text-align: center;
  font-weight: 700;
}

.bio-text-block {
  font-family: "Krub", sans-serif;
  color: #0a2c31;
  font-size: 22px;
  font-family: "Krub", sans-serif;
  line-height: normal;
  text-align: left;
  display: inline;
  width: 420px;
  margin-bottom: 30px;
}

.bio-text-block2 {
  font-family: "Krub", sans-serif;
  color: #0a2c31;
  font-size: 22px;
  font-family: "Krub", sans-serif;
  line-height: normal;
  text-align: left;
  display: inline;
  width: 900px;
}

.what {
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .bio-text-block2 {
    width: 470px;
  }
  .bio-text-block {
    width: 470px;
  }

  .name {
    width: 470px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .profile-picture {
    width: 300px;
    height: 401px;
    background-size: cover;
  }

  .bio {
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .name {
    font-size: 40px;
    width: 300px;
  }

  .bio-text-block {
    font-size: 20px;
    width: 300px;
    margin-bottom: 30px;
  }

  .bio-text-block2 {
    font-size: 20px;

    width: 300px;
  }
}
