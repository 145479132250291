.fixables {
  display: flex;
  flex-direction: column;
  padding: 50px 0;

  z-index: 20;
}

.fix-bubbles {
  display: flex;
  align-content: space-around;
  margin: 0 auto;
}

.fix-facts {
  display: flex;
  justify-content: center;
  align-content: space-around;
  margin: 30px;
  gap: 30px;
}

.fix-facts-icon {
  width: 250px;
  height: 250px;
  background-size: cover;
  filter: invert(88%) sepia(45%) saturate(3735%) hue-rotate(322deg)
    brightness(101%) contrast(103%);
}

.fix-facts-text {
  max-width: 800px;
  background-color: rgba(119, 211, 194, 0.7);
  border-radius: 20px;
  color: #0a2c31;
  font-family: "Krub", sans-serif;
  font-size: 20px;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.solution-tagline {
  color: #ffbd42;
  font-size: 30px;
  font-family: "Kaisei Decol", serif;
  font-weight: 700;
  margin-top: 40px;
}

.fix-bubble {
  width: 200px;
  height: 50px;
  background-color: rgba(119, 211, 194, 0.75);
  border-radius: 50% 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  color: #0a2c31;
  font-family: "Krub", sans-serif;
  font-size: 20px;
  transition: 0.3s;
}

.fix-bubble:hover {
  background-color: rgba(119, 211, 194, 1);
  transition: 0.3s;
}

.fix-bubble-active {
  background-color: rgba(119, 211, 194, 0.5);
  transition: 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .fix-bubbles {
    flex-wrap: wrap;
    width: 700px;
    align-items: center;
    justify-content: center;
  }

  .fix-bubble {
    width: 25%;
  }

  .fix-facts {
    width: 760px;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .fix-facts-text {
    width: 600px;

    margin: 0;
  }

  .fix-facts-icon {
    width: 150px;
    height: 150px;
  }

  .fixables {
    align-items: center;
  }

  .solution-tagline {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .fix-bubbles {
    flex-wrap: wrap;
    width: 350px;
    align-items: center;
    justify-content: center;
  }

  .fix-bubble {
    width: 40%;
    font-size: 15px;
  }

  .fixables {
    padding: 0;
    margin-top: -80px;
  }

  .fix-facts {
    width: 350px;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .fix-facts-text {
    width: 300px;

    margin: 0;
  }

  .fix-facts-icon {
    width: 100px;
    height: 100px;
  }

  .fixables {
    align-items: center;
  }

  .solution-tagline {
    margin: 10px;
    line-height: normal;
  }
}
