.request-button {
  background-color: rgba(255, 189, 66, 0.85);
  font-size: 30px;
  font-family: "Krub", sans-serif;
  padding: 30px;
  border-radius: 20px;
  color: #0a2c31;
}

.request-button:hover {
  background-color: rgba(255, 189, 66, 1);
}

.design-line {
  background-color: #77d3c2;
  width: 300px;
  height: 1px;
}

.request-box {
  margin: 60px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .request-button {
    font-size: 25px;

    padding: 20px;

    line-height: normal;
  }

  .design-line {
    width: 150px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .request-button {
    font-size: 20px;

    padding: 15px;

    line-height: normal;
  }

  .design-line {
    width: 20px;
  }

  .request-box {
    margin: 30px 0;
  }
}
