.header {
  height: 1060px;
}
.parallax {
  will-change: transform;
  transform: translateZ(0);
  transition: transform 0.3s ease-out;

  height: 1060px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  color: rgb(255, 189, 6);
  font-weight: 700;
  font-size: 80px;
  font-family: "Kaisei Decol", serif;
}

h2 {
  color: #fff;
  font-weight: 700;
  font-size: 100px;
  font-family: "Kaisei Decol", serif;
  z-index: 5;
}

.tagline {
  font-family: "Krub", sans-serif;
  font-size: 30px;
  color: rgb(255, 189, 6);
  font-weight: 700;
  margin: 50px;
  line-height: normal;
}

.header-button {
  background-color: rgba(255, 189, 6, 0.85);
  border-radius: 20px;
  max-width: 466px;
  padding: 15px;
  margin: 60px auto;

  font-size: 30px;
  font-family: "Krub", sans-serif;
}

.header-button:hover {
  background-color: rgba(255, 189, 6, 1);
}

.header-button-text {
  color: #fff;
}

.animation-layer {
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1024px;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  filter: brightness(60%);
}

.palm1 {
  background-image: url(../images/palm-paralax-1.png);
}
.palm2 {
  background-image: url(../images/palm-paralax-2.png);
}
.palm3 {
  background-image: url(../images/palm-paralax-3.png);
}
.palm4 {
  background-image: url(../images/palm-paralax-4.png);
}
.palm5 {
  background-image: url(../images/palm-paralax-5.png);
}
.palm6 {
  background-image: url(../images/palm-paralax-6.png);
}
.palm7 {
  background-image: url(../images/palm-paralax-7.png);
}

.first {
  z-index: 12;
  margin-top: 500px;
}

.mind {
  z-index: 12;
  margin-top: 300px;
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .mind {
    margin-top: 200px;
  }

  .parallax {
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .mind {
    margin-top: 250px;
  }

  h2 {
    font-size: 45px;
  }

  .tagline {
    font-size: 20px;
  }

  h1 {
    font-size: 60px;
  }

  .header-button {
    max-width: 250px;
    line-height: normal;
    font-size: 17px;
  }

  .animation-layer {
    height: 900px;
  }

  .parallax {
    height: 900px;
  }
}
