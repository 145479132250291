.footer {
  background-color: #0a2c31;
  height: 320px;
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
  align-items: center;
  font-family: "Krub", sans-serif;
}

.nav-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-list-item {
  color: rgba(255, 189, 66, 0.85);
  font-size: 20px;
  transition: 0.3s;
  text-align: left;
}
.nav-list-item:hover {
  color: rgba(255, 189, 66, 1);
  font-size: 22px;
}

.icon {
  height: 200px;
  width: 200px;
  background-image: url("../images/small-ying.png");

  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
}

.credit {
  color: #19707c;
  width: 245px;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  transition: 0.3s;
}

.credit:hover {
  color: #77d3c2;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .icon {
    height: 100px;
    width: 100px;
  }

  .nav-list-item {
    color: rgba(255, 189, 66, 0.85);
    font-size: 15px;
    transition: 0.3s;
    text-align: left;
  }
  .nav-list-item:hover {
    color: rgba(255, 189, 66, 1);
    font-size: 17px;
  }

  .credit {
    font-size: 15px;
  }
  .footer {
    height: 220px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .icon {
    height: 100px;
    width: 100px;
    opacity: 100%;
  }

  .nav-list-item {
    color: rgba(255, 189, 66, 0.85);
    font-size: 15px;
    transition: 0.3s;
    text-align: center;
    text-decoration: none;
  }

  .nav-list-item:hover {
    color: rgba(255, 189, 66, 1);
    font-size: 17px;
  }

  .credit {
    font-size: 15px;
  }
  .footer {
    height: 400px;
    flex-direction: column;
    padding: 20px;
  }
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
